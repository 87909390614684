import {
    useState,
    useMemo,
    useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import StickyTable from '../components/StickyTable';
import ItemRow from '../components/ItemRow';

import numberFormat from '../modules/number-format.mjs';
import runescapeNumberFormat from '../modules/runescape-number-format.mjs';
import loadJSON from '../modules/load-json.mjs';

import '../App.css';

const itemType = [
    '2h',
    'ammo',
    'body',
    'cape',
    'feet',
    'hands',
    'head',
    'legs',
    'neck',
    'ring',
    'shield',
    'weapon',
];

function Gear({mapping, latest, filter, volumes}) {
    const [selectedType, setSelectedType] = useState('2h');
    const [items, setItems] = useState([]);
    const rows = useMemo(() => {
        let itemRows = [];
        let nameCache = [];

        for(const item of items) {
            const newItem = {
                id: item.id,
                name: mapping[item.id]?.name || item.name,
                icon: `data:image/png;base64,${item.icon}`,
                crush: item.equipment.attack_crush,
                slash: item.equipment.attack_slash,
                stab: item.equipment.attack_stab,
                magic: item.equipment.attack_magic,
                ranged: item.equipment.attack_ranged,
                meleeStrength: item.equipment.melee_strength,
                rangedStrength: item.equipment.ranged_strength,
                magicDamage: item.equipment.magic_damage,
                prayer: item.equipment.prayer,
                weight: item.weight,
                requirements: item.equipment.requirements,
                crushDefence: item.equipment.defence_crush,
                slashDefence: item.equipment.defence_slash,
                stabDefence: item.equipment.defence_stab,
                magicDefence: item.equipment.defence_magic,
                rangedDefence: item.equipment.defence_ranged,
                high: latest[item.id]?.high || 0,
            };

            if(nameCache.includes(newItem.name)) {
                continue;
            }

            if(filter && !newItem.name.includes(filter)){
                continue;
            }

            nameCache.push(newItem.name);
            itemRows.push(newItem);
        };

        return itemRows;
    }, [items, filter, mapping, latest]);

    useEffect(() => {
        async function fetchData(params) {
            const itemData = await loadJSON(`${process.env.PUBLIC_URL}/items-${selectedType}.json`);

            setItems(Object.values(itemData));
        }

        fetchData();
    }, [selectedType]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Result',
            renderCell: ({row}) => {
                return (
                    <div>
                        <ItemRow
                            name={row.name}
                            icon={row.icon}
                            id={row.itemId}
                        />
                        <div
                            className='subtext'
                        >
                            {runescapeNumberFormat(row.high)}
                        </div>
                    </div>
                );
            },
            minWidth: 150,
        },
        {
            align: 'center',
            field: 'crush',
            headerName: 'Crush',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'slash',
            headerName: 'Slash',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'stab',
            headerName: 'Stab',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'magic',
            headerName: 'Magic',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'ranged',
            headerName: 'Ranged',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'meleeStrength',
            headerName: 'Melee Strength',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'rangedStrength',
            headerName: 'Ranged Strength',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'magicDamage',
            headerName: 'Magic Damage',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'prayer',
            headerName: 'Prayer',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'crushDefence',
            headerName: 'Crush Defence',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'slashDefence',
            headerName: 'Slash Defence',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'stabDefence',
            headerName: 'Stab Defence',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'magicDefence',
            headerName: 'Magic Defence',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'rangedDefence',
            headerName: 'Ranged Defence',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
        {
            align: 'center',
            field: 'weight',
            headerName: 'Weight',
            renderCell: ({ value }) => numberFormat(value) || '',
            width: 60,
        },
    ];

    // const calculateRowHeight = (params) => {
    //     const uniqueItems = [...new Set(params.model.input)];

    //     return uniqueItems.length * 25 + (16 * params.densityFactor);
    // };

    return <Box
        component="form"
        noValidate
        autoComplete="off"
    >
        <title>
            Gear Stats - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant='h1'
            >
                {`Gear Stats`}
            </Typography>
            <ButtonGroup size="small" aria-label="Small button group">
                {itemType.map((type) => {
                    return <Button
                        key={type}
                        onClick={() => setSelectedType(type)}
                        variant={selectedType === type ? 'contained' : 'outlined'}
                    >
                        {type}
                    </Button>;
                })}
            </ButtonGroup>
            <StickyTable
                density="standard"
                rows={rows}
                columns={columns}
                // getRowHeight={calculateRowHeight}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [{
                            field: 'roi',
                            sort: 'desc',
                        }],
                    },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                // hideFooter
            />
        </Container>
    </Box>;
}

export default Gear;
