import isDataUrl from '../modules/is-data-url.mjs';

const ItemIcon = ({name, icon}) => {
    let type = 'uri';
    let src = '';

    if(isDataUrl(icon)) {
        type = 'base64';
        src = icon;
    }

    if(type === 'uri'){
        icon = icon?.replace(/ /g, '_');
        src = `https://oldschool.runescape.wiki/images/${icon}?cache`;
    }

    return (
        <div
            className="item-image-wrapper"
        >
            <img
                alt = {`${name} icon`}
                src={src}
            />
        </div>
    );
};

export default ItemIcon;
